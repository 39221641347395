import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import { flatQuery } from 'utils/queries'
import Layout from 'components/Layout/Layout'

import gridStyles from 'styles/grid.module.scss'

const NavPage = ({ data: { allWordpressPage }, location }) => (
  <Layout location={location}>
    <div className={gridStyles.lbhContainer}>
      <h1>A to Z</h1>
      <ul>
        {flatQuery(allWordpressPage).map(({ slug, title }) => (
          <li key={slug}>
            <Link to={`/${slug}`}>
              <span
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </Layout>
)

NavPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    allWordpressPage: PropTypes.shape({
      egdes: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired
      ),
    }).isRequired,
  }).isRequired,
}

export default NavPage

export const pageQuery = graphql`
  query NavIndexQuery {
    allWordpressPage(
      filter: { acf: { show_or_hide_from_menu: { eq: "show" } } }
      sort: { fields: title }
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`
